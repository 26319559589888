alert = (function () {

    "use strict";

    var shade, albox, alert_tmpl;
    var regex = new RegExp("\\s", "g");

    function init() {

        shade = document.getElementById("shad_abox");
        albox = document.getElementById("abox");

        alert_tmpl = albox.innerHTML.trim();

        window.removeEventListener("load", init);
    }

    function closeAlertBox() {

        $(albox).fadeOut(400, function() {
            $('#abox .form_submit').css('display', 'none');
            $('#abox .form_submit.original').css('display', 'flex');
        });

        $(shade).removeClass('actif');
        $(shade).off("click", closeAlertBox);
        $('body').removeClass('alert_open');
    }

    function alert(str) {

        $('body').addClass('alert_open');

        $('.txt_alert', albox).html(str);

        $(".close", albox).on("click", closeAlertBox);
        $(shade).one("click", closeAlertBox);
        $(shade).addClass('actif');
        $(albox).fadeIn(400);
    }

    window.addEventListener("load", init);

    return alert;
}());