$(function () {

    "use strict";

    var header = document.getElementById("site_head_wrap");
    var search = document.getElementById("search_home");
    var shade = document.getElementById("shad_menu");

    function onWinScroll() {

        var scrollTop = $(document).scrollTop();

        if (header.classList.contains("sticky")) {

            if (scrollTop < 1) {
                header.classList.remove("sticky");
                shade.classList.remove("sticky");
                document.body.classList.remove("sticky");
                    $(".headerBottom .block_logo").insertBefore(".block_top_links.leftSide");
                    $(".headerBottom .block_top_links.rightSide").insertAfter(".block_top_links.leftSide");
            }
        } else {

            if (scrollTop > 1) {
                header.classList.add("sticky");
                shade.classList.add("sticky");
                document.body.classList.add("sticky");
                setTimeout(function () {
                    $(".headerTop .block_logo").insertBefore(".mainMenu");
                    $(".headerTop .block_top_links.rightSide").insertAfter(".mainMenu");
                }, 200);
            }
        }
        
    }

    function onSearchStateChange(evt) {

        if (evt.type === "focus") {

            search.parentElement.classList.add("searching");
        } else if (search.value.trim() === "") {

            search.parentElement.classList.remove("searching");
        }
    }

    //init swiper
    var swiperMainHome = $('.sliderMainHome');

    if (swiperMainHome.length) {

        var count = 1;       
        // store last played video to put in pause when slide
        var lastPlayedVideo = '';

        swiperMainHome.each(function() {
            //$(this).attr('id', 'super_home_swiper_' + count);
            //var swiperId = $(this).attr('id');
            var swiperHome = new Swiper(swiperMainHome, {
                centeredSlides: true,
                speed: 1500,
                autoplay: {
                    delay: 10000, 
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                roundLengths: true,
                resistanceRatio: 0,
            });
            
            /*$(this).hover(function () {
                swiperHome.autoplay.stop();
            }, function () {
                swiperHome.autoplay.start();
            });*/
    
            /*swiperHome.on('slideChangeTransitionEnd', function () {
                //when a video is displayed
                //stop the old one
                //plays the new one
                //stop the slider until the end
                //store the video as last played
                if ($(this + ' .swiper-slide-active').find('iframe.vimeo_video').length) {
                    swiperHome.autoplay.stop();
                    $(this + ' .swiper-slide-active').find('iframe.vimeo_video').each(function () {
                        var videoId = $(this).attr('id');
                        setLastVideoPause(videoId);
                        vimeoPlayers[videoId].play();
                        lastPlayedVideo = videoId;
                    });
                } else if ($(this + ' .swiper-slide-active').find('iframe.youtube_video').length) {
                    swiperHome.autoplay.stop();
                    $(this + ' .swiper-slide-active').find('iframe.youtube_video').each(function () {
                        var videoId = $(this).attr('id');
                        setLastVideoPause(videoId);
                        youtubePlayers[videoId].playVideo();
                        lastPlayedVideo = videoId;
                    });
                }                
            })
            count++;*/
        });
    
        //init and store all youtube players
       /* var youtubePlayers = {};
        if ($(this + ' .swiper-slide iframe.youtube_video').length >= 1) {
            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScript = document.getElementsByTagName('script')[0];
            firstScript.parentNode.insertBefore(tag, firstScript);
    
            window.onYouTubeIframeAPIReady = function () {
                $(this + ' .swiper-slide iframe.youtube_video').each(function () {
                    var videoId = $(this).attr('id');
                    var youtubePlayer = new YT.Player(videoId, {
                        //add a listener to start the slider autoplay again
                        events: {
                            onStateChange: function (evt) {
                                if (evt.data === YT.PlayerState.ENDED) {
                                    evt.target.seekTo(0);
                                    evt.target.pauseVideo();
                                    swiperHome.autoplay.start();
                                }
                            }
                        }
                    });
                    youtubePlayers[videoId] = youtubePlayer;
                });
    
            };      
        }*/
    }   

    //with the stored, put the last video in pause
    function setLastVideoPause(newPlayedVideo) {
        if(lastPlayedVideo!='') {
            if($('#'+lastPlayedVideo).hasClass('vimeo_video')) {
                vimeoPlayers[lastPlayedVideo].pause();
            }
            else if($('#'+lastPlayedVideo).hasClass('youtube_video')) {
                youtubePlayers[lastPlayedVideo].pauseVideo();
            }
            lastPlayedVideo = newPlayedVideo;
        }
    }


    $(window).on("scroll", onWinScroll);
    $(search).on({focus: onSearchStateChange, blur: onSearchStateChange});

    onWinScroll();

    // Initialisation du slider gondoles si présent
    var simpleGondoles = $('.simple-gondole');
    var cptSwiper = 0;
    if (simpleGondoles) {
        $(simpleGondoles).each(function(e) {
            cptSwiper ++;
            var swiperGondoles = $(this).find('.swiperTg') ;
            swiperGondoles.attr('id','swiperTg-'+cptSwiper);
            var newSwiperId = swiperGondoles.attr('id');

            var swiperTg = new Swiper('#' + newSwiperId, {
                slidesPerView: 4,
                spaceBetween: 30,
                speed: 1500,
                autoplay: false,
                navigation: {
                    nextEl: '#' + newSwiperId + '~.swiper-button-next',
                    prevEl: '#' + newSwiperId + '~.swiper-button-prev',
                }
            });
        });
    }
});

function dataLayer_associated_product_select_item () {

    if (window.$('.swiper-container.swiperTg.dtl-product')) {
        var product_id = this.getAttribute('data-prod') ? event.currentTarget.getAttribute('data-prod') : '';

        if (product_id !== '') {
            if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null ) {
                DataLayerTrigger.selectItem(dl_v3_impressions, dlv3_action_fields, product_id);
            }
        }
    }
}