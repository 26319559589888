var addCartWrapper = $(".btnAddBasketWrapper");
var disabledBtnTooltip = $(".disabledBtnTooltip");

// Pour l'ajout à la wishlist on regarde si on a selectionné la taille et la couleur
// (on se base sur le statut du bouton btnAddBasketWrapper qui change en fonction de ces paramètres)
$("#addToWishlistButton").on({
    click: function() {
        addToWishlist();
    }
});

/*
 * Tous ce qui concerne la gestion des wishlist (fiche produit et page wishlist)
 * product_right.php, wishlist_index.php
 */

var button_add_to_wishlist_initial_click_event;
var button_add_to_wishlist_initial_label;

function restoreWishlistButtonState() {
    $("#addToWishlistButton span").html(button_add_to_wishlist_initial_label);
    $("#addToWishlistButton").removeClass("existToWishlistButton").addClass("addToWishlistButton");
    $("#addToWishlistButton").attr("onclick", button_add_to_wishlist_initial_click_event).attr("href", "#");
}

/**
 * Dans la fiche produit. Le bouton ajouter à la wishlist. (product_right.php)
 */
function addToWishlist() {
    var btn = $(this);
    var idElt = btn.data('idelt');
    var productId = (btn.data('productid') ? btn.data('productid') : $("#produit_id").val());
    var colorId = $('#couleurProd_' + idElt).length == 1 ? $('#couleurProd_' + idElt).val() : $('#couleurProd').val();
    var sizeId = $('#tailleProd_' + idElt).length == 1 ? $('#tailleProd_' + idElt).val() : $('#tailleProd').val();
    var datas = {
        product_id: productId,
        color_id: colorId,
        size_id: sizeId,
        need_color_id: 'true',
        need_size_id: 'true'
    };

    $.ajax({
        url: path_relative_root + "ajax_add_to_wishlist.php",
        type : 'post',
        data: datas
    } )

    .done(function(resp) {
        var $alertboxLogin = $("#abox");
        var $alertboxBtns = $("#abox .wrap_btn_abox");
        $alertboxLogin.addClass('box_wishlist');

        if (resp.error) {

            var message = resp.result.error_message ? resp.result.error_message : resp.message;

            if (resp.message === "login_required") {
                var wishlistSubmit = $( '.form_submit.form_submit_wishlist', $alertboxLogin );
                
                if (wishlistSubmit.length === 0) {
                    $alertboxBtns
                        .append($(
                            '<div class="form_submit form_submit_wishlist">' +
                            '<button class="button dark"  href="javascript:void(0)" onclick="wishlistRedirectLogin(\'' + productId + '\');">' +
                            '<span>' +
                            resp.result.btn_connexion +
                            '</span>' +
                            '</button>' +
                            '</div>')
                        );
                } else {
                    wishlistSubmit.css('display', 'flex');
                }

                // On cache le bouton original
                $('#abox .form_submit.original').css('display', 'none');
            }

            if (resp.result.error_message) {
                alert(Translator.translate(resp.result.error_message));
            } else {
                alert(Translator.translate('quick_purchase_choose_a_size_and_a_color'));
                return false;
            }
        } else {

            //evenement DataLayer
            if (window.dataLayer !== undefined) {
                window.dataLayer.push({
                    'event': 'addToWishlist',
                    'products': {
                        'product_id': productId,
                    }
                });
            }

            btn.addClass("existToWishlistButton");
            
            /* FP */
            if ($('body.product_page').length) {
                $("#addToWishlistButton").addClass("existToWishlistButton");
            }

            btn.attr( "onclick", "" );
            
            if (typeof wishlist !== 'undefined') {
            }
            if (parseInt($('#in_basket').val()) === 1) {
                var btn_wish = "#addToWishlistButton_" + product_id;
                var span_wish = btn_wish + " span";
                var del_product = "#del_product_" + product_id;

                btn_wish = btn_wish.replace(',', '');
                span_wish = span_wish.replace(',', '');
                del_product = del_product.replace(',', '');

                $(span_wish).html(resp.result.button_label);
                $(btn_wish).removeClass("addToWishlistButton").addClass("existToWishlistButton");
                $(btn_wish + ".bgSprite.wish").removeClass("wish").addClass("wishFull");
                $(btn_wish).attr("onclick", "").attr("href", path_relative_root + create_link('wishlist'));

                addToWishlistUpdateHeader(resp.result.wishlist_size);
                $(del_product)[0].click();
            } else {
                $("#addToWishlistButton span").html(resp.result.button_label);
                $("#addToWishlistButton").removeClass("addToWishlistButton").addClass("existToWishlistButton");
                $("#addToWishlistButton .bgSprite.wish").removeClass("wish").addClass("wishFull");
                $("#addToWishlistButton").attr("onclick", "").attr("href", path_relative_root + create_link('wishlist'));

                btn.addClass("existToWishlistButton");

                addToWishlistUpdateHeader(resp.result.wishlist_size);
            }
            addToWishlistUpdateHeader(resp.result.wishlist_size);
        }
    });
}

function addWishListNoConnect(prod_id) {
    $.post(
        path_relative_root + "ajax_add_to_wishlist.php?time=" + Date.now(),

        function (resp) {
            var $alertboxLogin = $("#abox");
            var $alertboxBtns = $("#abox .wrap_btn_abox")

            var html =
                '<div class="light_title"></div>';

            if (resp.error) {
                if ($alertboxLogin.length == 0) {
                    $alertboxLogin = $(
                        '<div id="abox">' + html + "</div>"
                    );
                }

                if (resp.message == "login_required") {
                    if ($('#abox .btn_container.btn_cnx').length == 0) {
                        $alertboxBtns
                            .append($(
                                '<div class="btn_container btn_cnx">' +
                                    '<button class="button dark"  href="javascript:void(0)" onclick="wishlistRedirectLogin(\'' + prod_id + '\');">' +
                                        '<span>' +
                                            resp.result.btn_connexion +
                                        '</span>' +
                                    '</button>' +
                                    '<div class="loader loading">' +
                                        '<span>' +
                                        '</span>' +
                                    '</div>' +
                                '</div>')
                            ); 
                        $alertboxLogin
                            .find(".txt_alert")
                            .html(
                                '<div class="box_wishlist">' +
                                    resp.result.error_message +
                                '</div>'
                            );

                    }

                    $('#abox .form_submit').css('display', 'none');
                    $alertBox = $alertboxLogin;
                }

                openMultiShad('abox');
                $alertBox
                    .appendTo("body")
                    .show()
                    .css("z-index", "10100");

            } else {
                $("span", btn).html(resp.result.button_label);
                btn.removeClass("addToWishlistButton").addClass(
                    "existToWishlistButton"
                );
                btn.attr("onclick", "");
                if (typeof isWeddingSpace == "undefined") {
                    btn.attr("href", resp.result.button_link);
                }

                wishlist.push(resp.result.wishlist_row_array);
                addToWishlistUpdateHeader(resp.result.wishlist_size);
            }
        }
    )
}

function addWishNoConnect(div_id, user, reference, is_alliance, code_couleur, idprod, wishlist) {
    if (user) {
        ajax_achat_express(reference, is_alliance, code_couleur, idprod, wishlist);
    } else {
        addWishListNoConnect(div_id);
    }
}


function checkProductInWishlist(prod, itm_rr_id) {
    var prod_id = $("#produit_id").val();
    var prod_itm_rr_id;

    if (prod != undefined && prod) {
        prod_id = prod;
    }
    if (itm_rr_id != undefined && itm_rr_id) {
        prod_itm_rr_id = itm_rr_id;
    }

    var couleurProd = $("#couleurProd");

    if (couleurProd.length) {
        couleurProd = $("#couleurProd").val();
    } else if ($("#ligne_couleur_"+ prod + prod_itm_rr_id)) {
        couleurProd = $("#ligne_couleur_"+ prod + prod_itm_rr_id).find('input:checked').val();
    } else {
        couleurProd = 0;
    }

    var tailleProd = $("#tailleProd");
    if (tailleProd.length) {
        tailleProd = $("#tailleProd").val();
    } else if ($("#size_list_"+ prod + prod_itm_rr_id)) {
        tailleProd = $("#size_list_"+ prod + prod_itm_rr_id).find('input:checked').val();
    } else {
        tailleProd = 0;
    }
    
    if (typeof prod_id != "undefined" && typeof couleurProd != "undefined" && typeof tailleProd != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + 'get_user_wishlist.php',
            data:{'product_id': prod_id, 'size_id': tailleProd, 'color_id': couleurProd},
            success: function (response) {
                var userWishlist = JSON.parse(response);

                if (userWishlist.length) {
                    changeBtnAddWishlist(true, prod_id, prod_itm_rr_id);
                    return true;
                } else {
                    changeBtnAddWishlist(false, prod_id, prod_itm_rr_id);
                    return false;
                }
            }
        });
    } else {
        changeBtnAddWishlist(false);
        return false;
    }
}

/*
 * Permet de changer l'action et le visuel du bouton d'ajout à la wishlist
 * Aurelien Renault
 */
function changeBtnAddWishlist(in_wishlist, prod_id, prod_itm_rr_id) {
    var btn = $("#addToWishlistButton");
    var isAchatExpress = $('#is_achat_express_v2').val() != 0;

    if (isAchatExpress) {
        if (prod_id && prod_itm_rr_id) {
            btn = $("#addToWishlistButton_" + prod_id + prod_itm_rr_id);
        } else if (prod_id && !prod_itm_rr_id) {
            btn = $("#addToWishlistButton_" + prod_id);
        }
    }

    if (in_wishlist) {
        btn.attr("onclick", "");
        btn.attr("href", path_relative_root + create_link('wishlist'));
        $("#addToWishlistButton span").html(Translator.translate('exist_to_wishlist_button_label'));
        btn.addClass("existToWishlistButton");
    } else {
        btn.attr("onclick", button_add_to_wishlist_initial_click_event);
        btn.attr("href", "javascript:;");
        $("#addToWishlistButton span").html(Translator.translate('add_to_wishlist'));
        btn.removeClass("existToWishlistButton").addClass("addToWishlistButton");
    }
}

function wishlistRedirectLogin(div_id) {
    $.get(path_relative_root + "ajax_add_to_wishlist.php?login_required=1", function () {
        document.location.href = path_relative_root + create_link('connexion_login') + "?from=front&div_id=" + div_id;
    });
}

function addToWishlistUpdateHeader(nr) {
    $(".btn_top_wishlist .gris").html('(' + nr + ')');

    if (nr == 0) {
        $("#basket1").show();
        $("#basket2").hide();
        $(".btn_top_wishlist").hide();
        $("#number_wishlist_top").html('(0)');
    } else {
        $(".wrapper_menu_compte_container .item.wishlist").css({'display': 'block'});

        $("#basket2").show();
        $("#basket1").hide();
        $(".btn_top_wishlist").show();
        $("#number_wishlist_top").html('(' + nr + ')');
    }
    var response_basket = ajax_file(path_relative_root + create_link('ajax_show_wishlist'));
    $("#wishlist").html(response_basket);
    setTimeout(function () {
        if ($('#wishlist_top #cart_list .product-link').length > 2 ) {
            $("#wishlist_top #cart_list").overlayScrollbars({});
        }
        $(".show_top_wishlist").addClass('appear');
    }, 750);
}

function scanForChanges() {
    var totalPrice = 0;
    totalPoints = 0;
    selectedProducts = new Array();
    $(".checkbox_select_product").each(function () {
        var element = $(this);
        var id = element.attr("data-id");
        $("#wproduct_" + id).css("border", "none");

        if (element.is(':checked')) {
            $("#wproduct_" + id).css("border", "1px solid #e64b47");

            var price = parseFloat(element.attr('data-price'));

            if (price) {
                totalPrice += price;
            }

            selectedProducts.push(id);
            if (element.attr("pointscadeau")) {
                totalPoints += parseInt(element.attr("pointscadeau"));
            }
        }
    });
    var txt = "",
        text = "";
    var priceSpan = $("#total_price");
    switch (selectedProducts.length) {
        case 0:
            txt = $texts[0] + ' :';
            priceSpan.html('0<span class="devise">&euro;</span><sup></sup><span class="decimal_price"></span>');
            break;
        case 1:
            text = $texts[1];
        default:
            if (text == '')
                text = $texts[2];

            pricefloor = Math.floor(totalPrice);

            if (pricefloor == totalPrice) {
                decimal = '';
            } else {
                decimal = Math.round((totalPrice - pricefloor) * 100);
                decimal = '' + decimal;
                if (decimal.length == 1) {
                    decimal = decimal + '0';
                }
            }

            priceSpan.html(pricefloor + '<div class="price_cents"><span class="devise">&euro;</span><sup></sup><span class="decimal_price">,' + decimal + '</span></div>');
            txt = '<span class="wishlist_count">' + selectedProducts.length + '</span> ' + text + ' :';
    }

    $(".txt", "#nrArticlesSelected").html(txt);

}

function addProductToCart(wpId, pId, sId, cId) {
    $.post(path_relative_root + create_link('ajax_wishlist_add_product_to_cart'),
        {
            product_id: pId,
            size_id: sId,
            color_id: cId
        }, function (resp) {
            if (resp == "ok") {
                document.location.href = path_relative_root + create_link('order_basket');
            } else {
                if ($("#ckwp_" + wpId).attr("pointscadeau")) {
                    alert(Translator.translate('err_no_points'));
                }
            }
        });
}

/**
 * Ferme une popup par rapport à son nom et ferme aussi le shad
 * Utilisation : #sendwishlistbox qui est généré dinamiquement dans wishlist_index.php
 */
function closePopup(popup_id, shad_id) {
    if (shad_id == undefined) {
        shad_id = 'shad';
    }
    var $popup = $('#' + popup_id),
        $shad = $('#' + shad_id);

    $shad.unbind('click.popup');
    $popup.slideUp('slow', function () {
        $shad.fadeOut('slow', function () {
            if ($('#wrapper_sendwishlist').hasClass('sended')) {
                $('#sendwishlistbox').remove();
            }
        });
    });
}

/**
 * Affiche la lighbox de la wishlist, dynamiquement si la box n'existe pas
 * Utilisation : wishlist_index.php
 */
function showsendwishlistmodal() {

    if ($('#sendwishlistbox').length == 0) {
        $.ajax({
            url: path_relative_root + create_link('ajax_send_wishlist') + '?liste_produit=' + selectedProducts,
            success: function (response) {
                if ($('#sendwishlistbox').length == 0) {
                    $('body').append(response);
                    if ($('#g-recaptcha').length > 0) {

                        grecaptcha.render('g-recaptcha');
                    }
                }
            },
            complete: function () {
                openMultiShad('sendwishlistbox');
            }
        });
    } else {
        openMultiShad('sendwishlistbox');
    }
}


/**
 * Envoie le formulaire de la lightbox wishlistavec gestion des erreurs
 * Utilisation : wishlist_index.php
 */
function sendWishListForm() {

    $("#sendWishListSubmit").css({display: 'none'});
    $('#wrapper_loader_send').css({display: 'block'});
    var content_id = $("#send_wishlist_ajax");
    var content;
    if ($('#sendWishListToMails').length && $('#sendWishListToMessage').length && $('#sendWishListYourName').length && $('#sendWishListYourMail').length) {

        var to_mails = $('#sendWishListToMails').val();
        var to_message = $('#sendWishListToMessage').val();
        var your_name = $('#sendWishListYourName').val();
        var your_mail = $('#sendWishListYourMail').val();
        var token_security = $('#token_security_wishlist').val();
        var captcha = $('#g-recaptcha-response').val();

        $.ajax({
            url: path_relative_root + create_link('ajax_send_wishlist_form') + '?to_mails=' + to_mails + '&to_message=' + to_message + '&your_name=' + your_name + '&your_mail=' + your_mail + '&token_security=' + token_security + '&g-recaptcha-response=' + captcha
        }).done(function (response) {

            $('.zone_text').css({border: ''});

            if (response.split(',')[0] == 'ok') {

                var s_adresse = response.substr(3);

                var sing = (response.split(',').length > 2) ? 'sendfriend_ok_plur' : 'sendfriend_ok_sing';

                content = '<div>' + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=' + sing) + '</div>';
                content += '<p style=\"margin-top: 10px;margin-bottom: 30px;padding:0;text-align:center;\">' + s_adresse + '</p>';

            }
            if (response == '1' || response == '2') {

                content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=sendfriend_error_mail_friends_invalid') + "</p>";
                $('#sendWishListToMails').addClass('inputErr');
            } else {

                $('#sendWishListToMails').removeClass('inputErr');
            }
            if (response == '3') {

                content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=sendfriend_error_no_message') + "</p>";
                $('#sendWishListToMessage').addClass('inputErr');
            } else {

                $('#sendWishListToMessage').removeClass('inputErr');
            }
            if (response == '4') {

                content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=sendfriend_error_no_name') + "</p>";
                $('#sendWishListYourName').addClass('inputErr');
            } else {

                $('#sendWishListYourName').removeClass('inputErr');
            }
            if (response == '5' || response == '6') {

                $('#sendWishListYourMail').addClass('inputErr');

                if (response == '5') {
                    content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=sendfriend_error_no_my_mail') + "</p>";
                } else {
                    content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=sendfriend_error_my_mail_invalid') + "</p>";
                }
            } else {

                $('#sendWishListYourMail').removeClass('inputErr');
            }

            if (response == 'token_error') {
                content = "<p class=\"txt_err_sendfriend\">" + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=error_token_security') + "</p>";
            }

            if (response.split(',')[0] == '7') {
                var s_adresse = response.substr(3);
                var sing = (response.split(',').length > 2) ? 'sendfriend_no_ok_plur' : 'sendfriend_no_ok_sing';
                content = '<div>' + ajax_file(path_relative_root + create_link('ajax_sprintf') + '?arg1=' + sing) + '</div>';

            }
            if (response.split(',')[0] == '7' || response.split(',')[0] == 'token_error' || response.split(',')[0] == 'ok') {
                content_id = $("#sendfriend_alert_mail");

                $('#sendfriend_alert_mail').css({display: 'block', opacity: 0})
                $('#wrapper_sendwishlist').addClass('sended').css({
                    display: 'block',
                    opacity: 1
                }).animate({opacity: 0}, function () {
                    $('#wrapper_sendwishlist').css({display: 'none'});
                    $('#sendfriend_alert_mail').animate({opacity: 1}, function () {
                        $('#sendfriend_alert_mail').css({display: 'block'});
                    });
                });
            } else {
                $('#sendfriend_form_buttons').removeClass('loading');
            }

            content_id.html(content).css({display: 'block'});

        });

    }


    return false;
}

/**
 * Utiliser pour ajouter un magasin dans ses favoris (boutique_details)
 */
function addToMyFavorites(magasin_id, client_id) {

    var is_favorite_elem = document.getElementById('is_favorite_' + magasin_id);
    var btn_favorite_elem = $('#favorite_store_button');

    // Si l'action est de détruire alors on est dans favorites_stores et on souhaite supprimer le bloc en entier
    if (is_favorite_elem.value == '1') {

        var act = 'del';
    } else {

        var act = 'add';
    }

    $.ajax({
        url: path_relative_root + 'ajax_manage_favorites_stores.php',
        type: 'post',
        dataType: 'html',
        data: 'act=' + act + '&magasin_id=' + magasin_id,
        success: function (response) {

            if (response) {
                if (act == 'add') {
                    is_favorite_elem.value = 1;
                    $('#favorite_' + magasin_id).addClass('active');
                    var libelle = '<span>' + Translator.translate('delete_to_favorites') + '</span>';
                    btn_favorite_elem.addClass('active');

                } else {
                    is_favorite_elem.value = 0;
                    $('#favorite_' + magasin_id).removeClass('active');
                    var libelle = '<span>' + Translator.translate('add_to_favorites') + '</span>';
                    btn_favorite_elem.removeClass('active');

                }
                btn_favorite_elem.html(libelle);

            }
        }
    });
}

function removeToMyFavorites() {
    event.preventDefault();

    var is_favorite_elem = document.getElementById('is_favorite');

    var self = $(this);
    var magasin_id = self.attr('data-magasinid');
    var client_id = self.attr('data-clientid');
    var action = self.attr('data-action');

    // Si l'action est de détruire alors on est dans favorites_stores et on souhaite supprimer le bloc en entier
    if ( action == 'destroy' ) {
        var act = 'del';
    } else {
        // On est dans store_details et on doit recupérer le statut actuel du magasin pour savoir si on doit le supprimer ou l'ajouter
        if (is_favorite_elem.value == '1') {
            var act = 'del';
        } else {
            var act = 'add';
        }
    }

    $.ajax({
        url : path_relative_root + 'ajax_manage_favorites_stores.php',
        type : 'post',
        dataType : 'html',
        data : 'act=' + act + '&magasin_id=' + magasin_id,
        success : function (response) {

            if (response) {

                // Destruction du bloc
                if (action == 'destroy') {
                    self.fadeOut('fast', function() {
                        self.remove();
                    });
                } else {
                    if (act == 'add') {
                        is_favorite_elem.value = 1;
                    } else {
                        is_favorite_elem.value = 0;
                    }
                }
            }
        }
    });
}

/**
 * Permet d'ajour un produit à la wishlist via le rayon (ou la recherche)
 * Utilisation : app/_components/product/achat_express.php/achat_express_search.php
 */
function addToWishlistRay() {
    // On utilise le même que dans la fiche produit car cela fait la même chose
    // mais on donne un nom différent au cas où
    addToWishlist.call(this);
}